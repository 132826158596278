/** SCHOOL DEPLOY */

// Demo Escolar = "7shgOc2d14IihxlF3yrC"
// S. Pedro = "Lgu4tw2HzlmcRQJDS7ZK"
// QA = MHmskwsiv5cM620TRsQA

export const SCHOOL_DEPLOY = "MHmskwsiv5cM620TRsQA";


/* LOCAL */

// export const USER_ACTIONS_URL = 'http://localhost:5001/simulador-qa/us-central1/';
// export const STUDENT_ACTIONS_URL = 'http://localhost:5001/simulador-qa/us-central1/';
// export const GROUP_ACTIONS_URL = 'http://localhost:5001/simulador-qa/us-central1/';

/* Q A */

// export const USER_ACTIONS_URL = 'https://us-central1-simulador-qa.cloudfunctions.net/';
// export const STUDENT_ACTIONS_URL = 'https://us-central1-simulador-qa.cloudfunctions.net/';
// export const GROUP_ACTIONS_URL = 'https://us-central1-simulador-qa.cloudfunctions.net/'; 


/* LOCAL PRODUCTION */

// export const USER_ACTIONS_URL = 'http://localhost:5001/schoolmarket2021/us-central1/';
// export const STUDENT_ACTIONS_URL = 'http://localhost:5001/schoolmarket2021/us-central1/';
// export const GROUP_ACTIONS_URL = 'http://localhost:5001/schoolmarket2021/us-central1/';

/* A U T E N T I C A C I Ó N */

// export const AUTH_URL = 'http://localhost:5001/simulador-qa/us-central1/';
// export const AUTH_URL = 'https://us-central1-simulador-qa.cloudfunctions.net/';
// export const AUTH_URL = 'http://localhost:5001/schoolmarket2021/us-central1/';



/** PRODUCTION */

export const USER_ACTIONS_URL = 'https://us-central1-schoolmarket2021.cloudfunctions.net/';
export const STUDENT_ACTIONS_URL = 'https://us-central1-schoolmarket2021.cloudfunctions.net/';
export const GROUP_ACTIONS_URL = 'https://us-central1-schoolmarket2021.cloudfunctions.net/';
export const AUTH_URL = 'https://us-central1-schoolmarket2021.cloudfunctions.net/';
export const IMAGES_URL = 'https://firebasestorage.googleapis.com/v0/b/schoolmarket2021.appspot.com/o/';





/** EMAIL */
// export const MAILING_URL = 'http://localhost:3003/simulador';
export const MAILING_URL = 'https://mailingapp.azurewebsites.net/simulador';


/** IMAGES */
// export const IMAGES_URL = 'https://firebasestorage.googleapis.com/v0/b/simulador-qa.appspot.com/o/';



/** AQUI SE VALIDAN LOS TEXTO DE LAS ESCUELAS */
export const SCHOOLINFORMATION = (subdomain) => {

    subdomain = "qa";

    let infoSchool = {
        schoolName: 'San Pedro',
        privateHomePhrase: 'Educar para la vida',
        publicHomePhrase: 'Animar a emprender a personas que no están preparadas no es fomentar el espíritu emprendedor, es un ejercicio de irresponsabilidad. "El libro negro del emprendedor"'
    };

    switch (subdomain) {
        case 'demoescolar':
            infoSchool.schoolName = 'Activo México';
            infoSchool.privateHomePhrase = 'Desarrollo de habilidades verbales y socio emocionales';
            infoSchool.publicHomePhrase = 'Animar a emprender a personas que no están preparadas no es fomentar el espíritu emprendedor, es un ejercicio de irresponsabilidad. "El libro negro del emprendedor"'
            break;
        case 'osmar':
            infoSchool.schoolName = 'Miguel Hidalgo';
            infoSchool.privateHomePhrase = 'Educando el futuro';
            infoSchool.publicHomePhrase = 'Tenemos que preparar a los estudiantes para su futuro, no para nuestro pasado';
            break;
        case 'qa':
            infoSchool.schoolName = 'Quality Assurance';
            infoSchool.privateHomePhrase = 'Control de calidad';
            infoSchool.publicHomePhrase = 'Beta Version';
            break;
        default:
            break;
    }

    return infoSchool;

}